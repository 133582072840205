import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import { ToastContainer } from "react-toastify";
import './index.css';
import "./multistep.css";

import './styles/css/interface_connexion.css'
import './styles/css/interface_connexion3.css'
import './styles/css/password.css'

import "./styles/css/link.css"

import "./styles/vendor/select2/select2.custom.css"
import "./styles/vendor/select2/select2-bootstrap-5-theme.custom.css"
import "./styles/vendor/bootstrap-5.2/css/bootstrap.custom.css"
import "./styles/css/UI_design_v2.css"
import "./styles/css/UI_interface_v2.css"
import "./styles/css/UI_text_v2.css"
import "./styles/css/UI_skeleton.css"

import './styles/css/UI_size_medium.css'
import './styles/css/UI_design_v2_responsive.css'

import './styles/css/data-tables.css'

import './styles/style.css'

import './styles/vendor/fontawesome/css/fontawesome.min.css'
import './styles/vendor/fontawesome/css/solid.min.css'

import './styles/vendor/select2/select2.custom.css'

import "react-agenda/build/styles.css"
import "react-datetime/css/react-datetime.css"

import "primereact/resources/themes/lara-light-indigo/theme.css"  //theme
import "primereact/resources/primereact.min.css"              //core css
import "primeicons/primeicons.css"

import "./styles/css/googleapis_css1.css"
import "./styles/css/googleapis_css2.css"
import "./styles/css/googleapis_css3.css"

import "../src/interceptors/axios"

// Crashlitics
import * as Sentry from "@sentry/browser";

Sentry.init({ dsn: process.env.REACT_APP_DSN });

// if (process.env.NODE_ENV !== "development")
//     console.log = () => {};

// ReactDOM.render(React.createElement(App, { context: document.getElementById('root') }), document.getElementById('root'))
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Fragment>
    {/* <ToastContainer /> */}
    <App />
  </Fragment>
)
