
import {Gestion_VIH_TYPES} from '../../types/vih_types'



const initial_get_liste_vih_a_consulter = {
    isLoading: false,
    Data_VIH_A_Consulter_reducers: [],
    error: "",
};

export const GET_list_patient_a_consulter_reducers = (state=initial_get_liste_vih_a_consulter, action) => {

    switch (action.type) {
        case Gestion_VIH_TYPES.GET_LISTE_PATIENT_VIH_A_CONSULTER_START:
            return {
                ...state,
                isLoading: true,
                Data_VIH_A_Consulter_reducers : null,
                error : null
            };
        case Gestion_VIH_TYPES.GET_LISTE_PATIENT_VIH_A_CONSULTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Data_VIH_A_Consulter_reducers: action.payload,
                error : null
            };


        case Gestion_VIH_TYPES.GET_LISTE_PATIENT_VIH_A_CONSULTER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                Data_VIH_A_Consulter_reducers : null
            };
        default:
            return state;
    }
}


const get_detail_patien_a_consulter_initial = {
    isLoading: false,
    Data_Detail_VIH_A_Consulter_reducers: [],
    error: "",
};

export const GET_Details_patient_a_consulter_reducers = (state=get_detail_patien_a_consulter_initial, action) => {

    switch (action.type) {
        case Gestion_VIH_TYPES.GET_DETAILS_PATIENT_VIH_A_CONSULTER_START:
            return {
                ...state,
                isLoading: true,
                Data_Detail_VIH_A_Consulter_reducers : null,
                error : null
            };
        case Gestion_VIH_TYPES.GET_DETAILS_PATIENT_VIH_A_CONSULTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Data_Detail_VIH_A_Consulter_reducers: action.payload,
                error : null
            };


        case Gestion_VIH_TYPES.GET_DETAILS_PATIENT_VIH_A_CONSULTER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                Data_Detail_VIH_A_Consulter_reducers : null
            };
        default:
            return state;
    }
}


const put_detail_patien_a_consulter_initial = {
    isLoading: false,
    Data_put_VIH_A_Consulter_reducers: [],
    error: "",
};


export const PUT_Details_patient_a_consulter_reducers = (state=put_detail_patien_a_consulter_initial, action) => {

    switch (action.type) {
        case Gestion_VIH_TYPES.PUT_DETAILS_PATIENT_VIH_A_CONSULTER_START:
            return {
                ...state,
                isLoading: true,
                Data_put_VIH_A_Consulter_reducers : null,
                error : null 
            };
        case Gestion_VIH_TYPES.PUT_DETAILS_PATIENT_VIH_A_CONSULTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Data_put_VIH_A_Consulter_reducers: action.payload,
                error : null
            };


        case Gestion_VIH_TYPES.PUT_DETAILS_PATIENT_VIH_A_CONSULTER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                Data_put_VIH_A_Consulter_reducers : null
            };
        default:
            return state;
    }
}


const list_terminer_vih_initial = {
    isLoading: false,
    Data_Liste_Terminer: [],
    error: "",
};


export const list_consultation_terminer_vih_reducers = (state=list_terminer_vih_initial, action) => {

    switch (action.type) {
        case Gestion_VIH_TYPES.LIST_TERMINER_VIH_A_CONSULTER_START:
            return {
                ...state,
                isLoading: true,
                Data_Liste_Terminer : null,
                error : null 
            };
        case Gestion_VIH_TYPES.LIST_TERMINER_VIH_A_CONSULTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Data_Liste_Terminer: action.payload,
                error : null
            };


        case Gestion_VIH_TYPES.LIST_TERMINER_VIH_A_CONSULTER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                Data_Liste_Terminer : null
            };
        default:
            return state;
    }
}


const terminer_vih_initial = {
    isLoading: false,
    Data_terminer_consultation: [],
    error: "",
};

export const terminer_consultation_vih_reducers = (state=terminer_vih_initial, action) => {

    switch (action.type) {
        case Gestion_VIH_TYPES.TERMINER_VIH_A_CONSULTER_START:
            return {
                ...state,
                isLoading: true,
                Data_terminer_consultation : null,
                error : null 
            };
        case Gestion_VIH_TYPES.TERMINER_VIH_A_CONSULTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Data_terminer_consultation: action.payload,
                error : null
            };


        case Gestion_VIH_TYPES.TERMINER_VIH_A_CONSULTER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                Data_terminer_consultation : null
            };
        default:
            return state;
    }
}

