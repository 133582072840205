import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutVihAccueil = lazy(() =>
    import(
      "../../components/vih/LayoutVihAccueil"
    )
);

const LayoutListVihTerminer = lazy(() =>
    import(
      "../../components/vih/terminer/LayoutVihConsultationTerminer"
    )
);

const LayoutVihConsultationForm = lazy(() =>
    import(
      "../../components/vih/create/CreateVihConsultationsComponents"
    )
);



export default function VihRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterInfirmerie
        path="/vih"
        component={LayoutVihAccueil}
        />

<MyPrivateRouters.PrivateRouterInfirmerie
        path="/vih-consultation-termine"
        component={LayoutListVihTerminer}
        />

<MyPrivateRouters.PrivateRouterInfirmerie
        path="/vih-consultation/:id/:typeconsulte/encours"
        component={LayoutVihConsultationForm}
        />

<MyPrivateRouters.PrivateRouterInfirmerie
        path="/vih-rapport"
        component={LayoutVihAccueil}
        />

     
    </Switch>
  )
}
