export const Gestion_VIH_TYPES = {
    GET_LISTE_PATIENT_VIH_A_CONSULTER_START : "GET_LISTE_PATIENT_VIH_A_CONSULTER_START",
    GET_LISTE_PATIENT_VIH_A_CONSULTER_SUCCESS : "GET_LISTE_PATIENT_VIH_A_CONSULTER_SUCCESS",
    GET_LISTE_PATIENT_VIH_A_CONSULTER_FAILED : "GET_LISTE_PATIENT_VIH_A_CONSULTER_FAILED",



    GET_DETAILS_PATIENT_VIH_A_CONSULTER_START : "GET_DETAILS_PATIENT_VIH_A_CONSULTER_START",
    GET_DETAILS_PATIENT_VIH_A_CONSULTER_SUCCESS : "GET_DETAILS_PATIENT_VIH_A_CONSULTER_SUCCESS",
    GET_DETAILS_PATIENT_VIH_A_CONSULTER_FAILED : "GET_DETAILS_PATIENT_VIH_A_CONSULTER_FAILED",

    PUT_DETAILS_PATIENT_VIH_A_CONSULTER_START : "PUT_DETAILS_PATIENT_VIH_A_CONSULTER_START",
    PUT_DETAILS_PATIENT_VIH_A_CONSULTER_SUCCESS : "PUT_DETAILS_PATIENT_VIH_A_CONSULTER_SUCCESS",
    PUT_DETAILS_PATIENT_VIH_A_CONSULTER_FAILED : "PUT_DETAILS_PATIENT_VIH_A_CONSULTER_FAILED",


    LIST_TERMINER_VIH_A_CONSULTER_START : "LIST_TERMINER_VIH_A_CONSULTER_START",
    LIST_TERMINER_VIH_A_CONSULTER_SUCCESS : "LIST_TERMINER_VIH_A_CONSULTER_SUCCESS",
    LIST_TERMINER_VIH_A_CONSULTER_FAILED : "LIST_TERMINER_VIH_A_CONSULTER_FAILED",


    TERMINER_VIH_A_CONSULTER_START : "TERMINER_VIH_A_CONSULTER_START",
    TERMINER_VIH_A_CONSULTER_SUCCESS : "TERMINER_VIH_A_CONSULTER_SUCCESS",
    TERMINER_VIH_A_CONSULTER_FAILED : "TERMINER_VIH_A_CONSULTER_FAILED",
}