import {
  applyMiddleware,
  legacy_createStore as createStore,
  combineReducers,
} from "redux";
import thunk from "redux-thunk";
import logger from 'redux-logger'
import { composeWithDevTools } from "redux-devtools-extension";

//--------------------------------------------------------------------------//
//                        Redux persist Import Start                        //
//--------------------------------------------------------------------------//

import storage from 'redux-persist/lib/storage'
import {
   persistReducer,
   persistStore
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
} from 'redux-persist'

import { config } from '../Constants'
//--------------------------------------------------------------------------//
//                          Redux persist Import End                        //
//--------------------------------------------------------------------------//

//------------------------------------------------------------------------------------------------//
//------------------------            IMPORT REDUCERS START          -----------------------------//
//------------------------------------------------------------------------------------------------//

//################################################################################################//
//######################################## connexion #############################################//
//################################################################################################//

import initstate from "./reducers/connexion/userReducers";

//################################################################################################//
//######################################## connexion #############################################//
//################################################################################################//

//------------------------------------------------------------------------------------------------//

//################################################################################################//
//######################################### modules ##############################################//
//################################################################################################//

import moduleReducers from "./reducers/moduleReducers";

//################################################################################################//
//######################################### modules ##############################################//
//################################################################################################//

//------------------------------------------------------------------------------------------------//

//################################################################################################//
//##################################### configuration ############################################//
//################################################################################################//

import initServiceState from "./reducers/configuration/serviceReducers";
import initVilleState from "./reducers/configuration/villeReducers";
import initNiveauEtudeState from "./reducers/configuration/niveauEtudesReducers";
import initTypeCentreState from "./reducers/configuration/typeCentreReducers";
import initRegionSanitaireState from "./reducers/configuration/regionSanitaireReducers";
import initRegionsState from "./reducers/configuration/regionsReducers";
import initDistrictSanitaireState from "./reducers/configuration/districtSanitaireReducers";
import initCentreSanteState from "./reducers/configuration/centresantreReducers";
import { groupeListReducers } from "./reducers/configuration/groupeReducers";
import { batimentListReducers } from "./reducers/configuration/batimentReducers";
import { chambreListReducers } from "./reducers/configuration/chambreReducers";
import { litListReducers } from "./reducers/configuration/litReducers";
import { blocOperatoireListReducers } from "./reducers/configuration/blocOperatoireReducers";
import { professionnelsListReducer } from "./reducers/configuration/professionnelSanteReducer";
import { fichePaiementListReducer } from "./reducers/configuration/fichePaiementReducer";
import identificationReducers from "./reducers/identificationReducers";
import roleReducers from "./reducers/roleReducers";
import {logListReducers} from "./reducers/configuration/logReducers";

import specialiteReducers from "./reducers/specialiteReducers";
import { categorieBatimentListReducers } from "./reducers/configuration/categorieBatimentReducers";
import utilisateursReducers from "./reducers/utilisateursReducers";

import initMotifsCentreState from "./reducers/configuration/motifsCentreReducers";

import initAssurancesGlobales from "./reducers/assurancesGlobalesReducers"

import initParametreactesReducers from "./reducers/configuration/parametreActeLaboReducers"

//################################################################################################//
//##################################### configuration ############################################//
//################################################################################################//

//------------------------------------------------------------------------------------------------//

//################################################################################################//
//######################################### accueil ##############################################//
//################################################################################################//

import initMotifGlobaleState from "./reducers/motifGlobaleReducer";
import communeReducers from "./reducers/communeReducers";
import initPatientState from "./reducers/accueil/patientReducers";
import { patientPaymentSlipsReducer } from "./reducers/accueil/patientPaymentSlipsReducer";
import { patientListReducers } from "./reducers/patient/patientReducers";
import initFichePaiement from "./reducers/fichePaiementReducer";
import FichePaiementsDetailsReducer from "./reducers/FichePaiementsDetailsReducer";

import initCaisseOuverte from "./reducers/caisseOuverteReducer"
import { rdvListReducer, rdvDeleteReducer } from "./reducers/rdvReducer";
import { actesListReducer } from "./reducers/actesReducer";
import {maladiesReducer} from "./reducers/maladiesReducer";
import nationnaliteReducers from "./reducers/nationnaliteReducers";
import initSituationMatrimoniale from "./reducers/accueil/situationMatrimonialeReducers";
import initEthnie from "./reducers/accueil/ethnieReducers";
import initProfession from "./reducers/accueil/professionReducers";
import initLienParente from "./reducers/accueil/lienParenteReducers";
import initAssurancesPatient from "./reducers/accueil/assurancePatientReducers";
import { cartesReducer } from "./reducers/accueil/cartesReducer";
import professionPatientReducer from "./reducers/accueil/professionPatientReducer";
import parentCasUrgentReducer from "./reducers/accueil/parentCasUrgentReducer";
import typeCarteReducers from "./reducers/accueil/typeCarteReducers";
import { hospitalisationsAccueilsReducers } from "./reducers/accueil/hospitalisationsAccueilsReducers";

import searchPatientsReducers from "./reducers/accueil/searchPatientsReducers";




//################################################################################################//
//######################################### accueil ##############################################//
//################################################################################################//


//------------------------------------------------------------------------------------------------//

//################################################################################################//
//######################################### BILAN ##############################################//
//################################################################################################//

import {getBilansReducer} from "./reducers/bilans/getBilansReducer";


//################################################################################################//
//######################################### BILAN ##############################################//
//################################################################################################//
//------------------------------------------------------------------------------------------------//

//################################################################################################//
//###################################### statistiques ############################################//
//################################################################################################//

import {statistiqueReducers} from "./reducers/statistique/statistiqueReducers";
import {statFrequentationPatientsReducer} from "./reducers/statistique/statFrequentationPatients";
import {statFrequentationPatientsMoisReducer} from "./reducers/statistique/statFrequentationPatientsMois";

//################################################################################################//
//###################################### statistiques ############################################//
//################################################################################################//

//################################################################################################//
//##################################### Partogramme ############################################//
//################################################################################################//
import { partogrammeReducers, InforPatientPartoReducer, PosteListeEquipeMedicale, PutCreatePaartogrammePatientReducers, PosteCreatePartogrammePatienteReducers, InfoPartogrammeReducers, GetListePartoTerminer, Put_Terminer_Partogramme } from "./reducers/partogramme/partogrammeReducers";


//################################################################################################//
//##################################### Partogramme ############################################//
//################################################################################################//

//------------------------------------------------------------------------------------------------//

//################################################################################################//
//#################################### Medecine Generale #########################################//
//################################################################################################//

import patientAConsulterReducer from "./reducers/patientAConsulterReducer";
import patientConsulteReducer from "./reducers/patientConsulteReducer";
import {hospitalisationReducers} from "./reducers/medecineG/hospitalisationReducers";
import {demandeHospitalisationReducers} from "./reducers/medecineG/demandeHospitalisationReducers";
import initMedecineGenerale from './reducers/medecineG/consultationMGReducers'
import { typeOrdonnanceReducer } from "./reducers/typeOrdonnanceReducers";
import { medicamentReducers } from "./reducers/medicamentReducers";
import {posologieReducers} from "./reducers/posologieReducers";
import hospitalisationReducer from "./reducers/hospitalisationReducer";
import observationReducer from "./reducers/medecineG/observationReducer"
import examensReducer from "./reducers/examensReducer";
import { typeExamenReducers } from "./reducers/typeExamenReducers";

//################################################################################################//
//#################################### Medecine Generale #########################################//
//################################################################################################//

//------------------------------------------------------------------------------------------------//

//################################################################################################//
//######################################### caisse ###############################################//
//################################################################################################//

import initCaisseState from "./reducers/caisse/caisseReducer";

//################################################################################################//
//######################################### caisse ###############################################//
//################################################################################################//

//------------------------------------------------------------------------------------------------//

//################################################################################################//
//###################################### infirmerie ##############################################//
//################################################################################################//

import { patientInfListReducers } from "./reducers/patientInfirmierReducers";
import initInfirmerie from "./reducers/infirmerie/infirmerieReducers"

//################################################################################################//
//###################################### infirmerie ##############################################//
//################################################################################################//

//------------------------------------------------------------------------------------------------//

//################################################################################################//
//#################################### dossier patient ###########################################//
//################################################################################################//

import initDossiersPatients from "./reducers/dossiersPatients/dossiersPatientsReducers";

//################################################################################################//
//#################################### dossier patient ###########################################//
//################################################################################################//

//------------------------------------------------------------------------------------------------//

//################################################################################################//
//######################################## Maternite #############################################//
//################################################################################################//

import initMaternite from "./reducers/maternite/consultationMaterniteReducers"
import suiteCoucheReducer from "./reducers/SuiteCoucheReducer"

//################################################################################################//
//######################################## Maternite #############################################//
//################################################################################################//

//------------------------------------------------------------------------------------------------//

//################################################################################################//
//######################################## Gynecologie ###########################################//
//################################################################################################//

import {reducer, consultationGyneco} from './reducers/gynecologieReducer'

//################################################################################################//
//######################################## Gynecologie ###########################################//
//################################################################################################//

//------------------------------------------------------------------------------------------------//


//################################################################################################//
//###################################### laboratoire #############################################//
//################################################################################################//

import initLaboratoireState from "./reducers/laboratoire/consultationLaboratoireReducers";

//################################################################################################//
//###################################### laboratoire #############################################//
//################################################################################################//


//################################################################################################//
//####################################### radiologie #############################################//
//################################################################################################//

import initRadiologieState from "./reducers/radiologie/consultationRadiologieReducers";
import refInterneReducer from "./reducers/refInterneReducer";
import refExterneReducer from "./reducers/refExterneReducer";

//################################################################################################//
//####################################### radiologie #############################################//
//################################################################################################//


//################################################################################################//
//####################################### radiologie #############################################//
//################################################################################################//

import initFacturation from "./reducers/facturation/facturationReducers";

//################################################################################################//
//####################################### radiologie #############################################//
//################################################################################################//


//################################################################################################//
//##################################### Rapport #############################################//
//################################################################################################//

import {generateReportReducer} from './reducers/rapports/generateReportReducer';
import {getReportByIdReducer} from './reducers/rapports/getReportByIdReducer';
import {getReportsReducer} from './reducers/rapports/getReportsReducer';
import {deleteReportReducer} from './reducers/rapports/deleteReportReducer';
import {getTreatmentIndicatorReducer} from './reducers/rapports/getTreatmentIndicatorReducer';
import {getAllAssurancesReducer} from './reducers/rapports/getAllAssurancesReducer';
import {getAllCentersReducer} from './reducers/rapports/getAllCentersReducer';
import {getEvolutionRecettesReducer} from './reducers/rapports/getEvolutionRecettesReducer';
import {getEvolutionFrequentationsReducer} from './reducers/rapports/getEvolutionFrequentationsReducer';

//Professionnels
import {getAllProfessionnelsReducer} from './reducers/rapports/professionnels/getAllProfessionnelsReducer';
import {getAllSpecialiteReducer} from './reducers/rapports/professionnels/getAllSpecialiteReducer';
import {getDetailsProfessionnelReducer} from './reducers/rapports/professionnels/getDetailsProfessionnelReducer';


//PRESTATION FINANCIERE
import { getPrestationAssuranceReducer } from "./reducers/rapports/prestations/getPrestationAssuranceReducer";
import { getPrestationMotifReducer } from "./reducers/rapports/prestations/getPrestationMotifReducer";
import { getPrestationServiceReducer } from "./reducers/rapports/prestations/getPrestationServiceReducer";
import { getPrestationCentreReducer } from "./reducers/rapports/prestations/getPrestationCentreReducer";
import { getPrestationGratuiteReducer } from "./reducers/rapports/prestations/getPrestationGratuiteReducer";
import { getPrestationReduitsReducer } from "./reducers/rapports/prestations/getPrestationReduitsReducer";

import { getReportsByCenterEtatGeneralReducer } from "./reducers/rapports/getReportsByCenterEtatGeneralReducer";
import { getReportAllPatientsReducer } from "./reducers/rapports/getReportAllPatientsReducer";
import { getReportReferencementReducer } from "./reducers/rapports/getReportReferencementReducer";
import { getReportActvitiesReducer } from "./reducers/rapports/getReportActvitiesReducer";
import { getReportActvitiesESPCReducer } from "./reducers/rapports/getReportActvitiesESPCReducer";
import { getReportsMaladiesReducer } from "./reducers/rapports/getReportsMaladiesReducer";
import { getReportsDepistageCPNMaterniteReducer } from "./reducers/rapports/getReportsDepistageCPNMaterniteReducer";
import { getReportRedevancesPercuesReducer } from "./reducers/rapports/getReportRedevancesPercuesReducer";
import {getReportAgregeBilanCaisseReducer} from "./reducers/rapports/getReportAgregeBilanCaisseReducer"

import { getReportSynthesesReducer } from "./reducers/rapports/getReportSynthesesReducer";

import { getReportsPatientByMonthReducer } from "./reducers/rapports/getReportsPatientByMonthReducer";
import { getReportsPatientByServiceReducer } from "./reducers/rapports/getReportsPatientByServiceReducer";

import {getReportNutritionnelReducer} from "./reducers/rapports/getReportNutritionnelReducer"

import { getReportsLaboratoireReducer } from "./reducers/rapports/getReportsLaboratoireReducer";
import { getReportsRadiologieReducer } from "./reducers/rapports/getReportsRadiologieReducer";
import { getReportsEchographieReducer } from "./reducers/rapports/getReportsEchographieReducer";

import { getReportsControleMajorReducer } from "./reducers/rapports/getReportsControleMajorReducer"



//################################################################################################//
//##################################### Rapport #############################################//
//################################################################################################//



//################################################################################################//
//##################################### Ophtalmologie #############################################//
//################################################################################################//


import { getOrdonnanceLunetteReducer } from "./reducers/ophtalmologie/getOrdonnanceLunetteReducer";


//################################################################################################//
//##################################### Ophtalmologie #############################################//
//################################################################################################//






//######################################## Chirurgie #############################################//
//################################################################################################//

import initChirurgie from './reducers/chirurgie/consultationChirurgieReducers';
import decesReducer from "./reducers/decesReducer";
import soinsActesReducer from "./reducers/soinsActesReducer";

//################################################################################################//
//######################################## Chirurgie #############################################//
//################################################################################################//

import {controlePBFReducers} from "./reducers/controlePBF/controlePBFReducers"



//################################################################################################//
//####################################### echographie ############################################//
//################################################################################################//

import initEchographieState from "./reducers/echographie/consultationEchographieReducers";

//################################################################################################//
//####################################### echographie ############################################//
//################################################################################################//



//################################################################################################//
//##################################### Vaccination ##############################################//
//################################################################################################//

import initVaccination from "./reducers/vaccinationReducers";

//################################################################################################//
//##################################### Vaccination ##############################################//
//################################################################################################//



//################################################################################################//
//##################################### Modules VIH ##############################################//
//################################################################################################//

import { GET_Details_patient_a_consulter_reducers, GET_list_patient_a_consulter_reducers, list_consultation_terminer_vih_reducers, terminer_consultation_vih_reducers,PUT_Details_patient_a_consulter_reducers } from "./reducers/vih/vihRdeucers"

//------------------------------------------------------------------------------------------------//

//------------------------------------------------------------------------------------------------//
//--------------------------            IMPORT REDUCERS END          -----------------------------//
//------------------------------------------------------------------------------------------------//

import sidebarReducers from "./reducers/sidebarReducers"
import ExamenExterneReducer from "./reducers/ExamenExterneReducer";

//------------------------------------------------------------------------------------------------//
//###################### ROOT REDUCERS REDUX GLOBALE STATE (STROE) START #########################//
//------------------------------------------------------------------------------------------------//

const rootReducer = combineReducers({
  //#############################################//
  //################# connexion #################//
  //#############################################//

  userdata: initstate,

  //#############################################//
  //################# connexion #################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################## caisse ###################//
  //#############################################//

  caissedata: initCaisseState,

  //#############################################//
  //################## caisse ###################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //############### Statistiques ################//
  //#############################################//

  statistique: statistiqueReducers,
  statFrequentationPatients: statFrequentationPatientsReducer,
  statFrequentationPatientsMois: statFrequentationPatientsMoisReducer,

  //#############################################//
  //############### Statistiques ################//
  //#############################################//

  //---------------------------------------------//

    
  //#############################################//
  //############### partogrammes ################//
  //#############################################//

  partogrammes: partogrammeReducers,
  InforPatientPartogramme : InforPatientPartoReducer,
  PosteListeEquipeMedicale : PosteListeEquipeMedicale,
  startCreatePartogrammle : PutCreatePaartogrammePatientReducers,
  createPartogramme : PosteCreatePartogrammePatienteReducers,
  InforPartogrammeByPatient : InfoPartogrammeReducers,
  ListePartogrammesTermines : GetListePartoTerminer,
  TerminerPartogramme : Put_Terminer_Partogramme,
  //#############################################//
  //############### partogrammes ################//
  //#############################################//

  //---------------------------------------------//
  
  //#############################################//
  //################### Modules #################//
  //#############################################//

  moduledata: moduleReducers,

  //#############################################//
  //################### Modules #################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //############## Configuration ################//
  //#############################################//

  groupList: groupeListReducers,
  chambreList: chambreListReducers,
  batimentList: batimentListReducers,
  listLit: litListReducers,
  blocOperatoireData: blocOperatoireListReducers,
  servicesdata: initServiceState,
  villesdata: initVilleState,
  regionsdata: initRegionsState,
  niveauEtudesdata: initNiveauEtudeState,
  typecentredata: initTypeCentreState,
  regionsantiaredata: initRegionSanitaireState,
  districtsanitairedata: initDistrictSanitaireState,
  centresantedata: initCentreSanteState,
  professionnelsList: professionnelsListReducer,
  fichesPaiementsList: fichePaiementListReducer,
  categoriebatimentList: categorieBatimentListReducers,
  identificationdata: identificationReducers,
  roledata: roleReducers,
  specialitedata: specialiteReducers,
  utilisateursData: utilisateursReducers,

  motifsCentreData: initMotifsCentreState,
  assurancesGlobalesData: initAssurancesGlobales,

  logListDatas: logListReducers,
  parametreactesdata: initParametreactesReducers,

  //#############################################//
  //############## Configuration ################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################## Accueil ##################//
  //#############################################//

  communedata: communeReducers,
  patientsdata: initPatientState,
  patientsPaymentSlips: patientPaymentSlipsReducer,
  fichesPaiements: initFichePaiement,
  fichePaiementsDetails: FichePaiementsDetailsReducer,
  caisseOuverteData: initCaisseOuverte,
  rdvList: rdvListReducer,
  rdvDelete: rdvDeleteReducer,
  motifsGlobalesdata: initMotifGlobaleState,
  actesList: actesListReducer,
  maladiesData: maladiesReducer,
  nationnalitedata: nationnaliteReducers,
  lienParentedata: initLienParente,
  ethniedata: initEthnie,
  typeCartedata: typeCarteReducers,
  situationMatrimonialedata: initSituationMatrimoniale,
  professiondata: initProfession,
  assurancesPatientdata: initAssurancesPatient,
  carteinfo: cartesReducer,
  professionsPatientData: professionPatientReducer,
  parentCasUrgentData: parentCasUrgentReducer,
  patientList: patientListReducers,
  hospitalisationAccueilsData: hospitalisationsAccueilsReducers,

  searchPatients: searchPatientsReducers,

  examenExterneData: ExamenExterneReducer,

  //#############################################//
  //################## Accueil ##################//
  //#############################################//

  getBilans: getBilansReducer,

  //#############################################//
  //################## BILAN ##################//
  //#############################################//

  //#############################################//
  //################## BILAN ##################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //############# Dossiers Patients #############//
  //#############################################//

  dossiersPatientsdata: initDossiersPatients,

  //#############################################//
  //############# Dossiers Patients #############//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //############# Medecine Generale #############//
  //#############################################//

  typeOrdonnance: typeOrdonnanceReducer,
  typeExamen: typeExamenReducers,
  medicament: medicamentReducers,
  posolgieData: posologieReducers,
  patientAConsulterData: patientAConsulterReducer,
  patientConsulteData: patientConsulteReducer,
  demandeHospitalisationData: demandeHospitalisationReducers,
  miseEnObservationData: hospitalisationReducers,
  medecineGeneraleData: initMedecineGenerale,
  hospitalisationData: hospitalisationReducer,
  observationData: observationReducer,

  examensData: examensReducer,

  //#############################################//
  //############# Medecine Generale #############//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################# Infirmerie ################//
  //#############################################//

  patientinfirmeriedata: patientInfListReducers,
  infirmerieData: initInfirmerie,

  //#############################################//
  //################# Infirmerie ################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################# Maternite #################//
  //#############################################//

  materniteData: initMaternite,

  suiteCoucheData: suiteCoucheReducer,

  //#############################################//
  //################# Maternite #################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################ Gynecologie ################//
  //#############################################//

  gynecologieData: reducer,
  consultationGynecoData: consultationGyneco,

  //#############################################//
  //################ Gynecologie ################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################ laboratoire ################//
  //#############################################//

  laboratoireData: initLaboratoireState,

  //#############################################//
  //################ laboratoire ################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################ radiologie #################//
  //#############################################//

  radiologieData: initRadiologieState,

  //#############################################//
  //################ radiologie #################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################# refInterne #################//
  //#############################################//

  refInterneData: refInterneReducer,

  //#############################################//
  //################ refInterne #################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################# refExterne #################//
  //#############################################//

  refExterneData: refExterneReducer,

  //#############################################//
  //################ refExterne #################//
  //#############################################//

  //#############################################//
  //############### Facturation #################//
  //#############################################//

  facturationData: initFacturation,

  //#############################################//
  //############### Facturation #################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################### Deces ###################//
  //#############################################//

  decesData: decesReducer,

  //#############################################//
  //################### Deces ###################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################ Chirurgie ##################//
  //#############################################//

  chirurgieData: initChirurgie,

  //#############################################//
  //################ Chirurgie ##################//
  //#############################################//

  //#############################################//
  //################ Actes Soins ################//
  //#############################################//

  soinsActesData: soinsActesReducer,

  //#############################################//
  //################ Actes Soins ################//
  //#############################################//

  //################################################################################################//
  //##################################### Rapport #############################################//
  //################################################################################################//

  generateReport: generateReportReducer,
  getReport: getReportByIdReducer,
  getAllReports: getReportsReducer,
  deleteReport: deleteReportReducer,
  getTreatmentIndicator: getTreatmentIndicatorReducer,
  getAllAssurances: getAllAssurancesReducer,
  getAllCenters: getAllCentersReducer,
  getEvolutionFrequentations: getEvolutionFrequentationsReducer,
  getEvolutionRecettes: getEvolutionRecettesReducer,

  //Professionnels
  getAllProfessionnels: getAllProfessionnelsReducer,
  getAllSpecialites: getAllSpecialiteReducer,
  getDetailsProfessionnel: getDetailsProfessionnelReducer,

  //PRESTATION  FINANCIERE
  getReportAssurance: getPrestationAssuranceReducer,
  getReportPrestationsMotif: getPrestationMotifReducer,
  getReportPrestationsService: getPrestationServiceReducer,
  getPrestationCentre: getPrestationCentreReducer,
  getPrestationGratuite: getPrestationGratuiteReducer,
  getPrestationReduits: getPrestationReduitsReducer,

  getReportsByCenterEtatGeneral: getReportsByCenterEtatGeneralReducer,
  getReportAllPatients: getReportAllPatientsReducer,
  getReportReferencements: getReportReferencementReducer,
  getReportActvities: getReportActvitiesReducer,
  getReportActvitiesESPC: getReportActvitiesESPCReducer,
  getReportsMaladies: getReportsMaladiesReducer,
  getReportsDepistageCPNMaternite: getReportsDepistageCPNMaterniteReducer,

  getReportRedevancesPercues: getReportRedevancesPercuesReducer,

  getReportSyntheses: getReportSynthesesReducer,

  reportsPatientByMonth: getReportsPatientByMonthReducer,
  reportsPatientByService: getReportsPatientByServiceReducer,
  getReportAgregeBilanCaisse: getReportAgregeBilanCaisseReducer,

  getReportNutritionnel: getReportNutritionnelReducer,

  getReportsLaboratoire : getReportsLaboratoireReducer,

  getReportsRadiologie : getReportsRadiologieReducer,

  getReportsEchographie : getReportsEchographieReducer,

  getReportsControleMajor : getReportsControleMajorReducer,

  //################################################################################################//
  //##################################### Rapport #############################################//
  //################################################################################################//

  //################################################################################################//
  //##################################### ophtalmologie #############################################//
  //################################################################################################//

  getOrdonnanceLunette: getOrdonnanceLunetteReducer,

  //################################################################################################//
  //##################################### ophtalmologie #############################################//
  //################################################################################################//

  //---------------------------------------------//

  //#############################################//
  //################ echographie ################//
  //#############################################//

  echographieData: initEchographieState,

  //#############################################//
  //################ echographie ################//
  //#############################################//

  //---------------------------------------------//

  //#############################################//
  //################ vaccination ################//
  //#############################################//

  vaccinationData: initVaccination,

  //#############################################//
  //################ vaccination ################//
  //#############################################//

  sidebardata: sidebarReducers,


  //#############################################//
  //################ MOdules VIH ################//
  //#############################################//


  GET_list_patient_a_consulter_reducers: GET_list_patient_a_consulter_reducers,
  GET_Details_patient_a_consulter_store : GET_Details_patient_a_consulter_reducers,
  PUT_Details_patient_a_consulter_store : PUT_Details_patient_a_consulter_reducers,
  list_consultation_terminer_vih_store: list_consultation_terminer_vih_reducers,
  terminer_consultation_vih_store : terminer_consultation_vih_reducers,
  //---------------------------------------------//

  // ControlePBF reducers
  controlePBFReducer: controlePBFReducers,
});
//------------------------------------------------------------------------------------------------//
//####################### ROOT REDUCERS REDUX GLOBALE STATE (STORE) END ##########################//
//------------------------------------------------------------------------------------------------//


//--------------------------------------------------------------------------//
//                        Redux persist Import Start                        //
//--------------------------------------------------------------------------//

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "communedata",
    "motifsList",
    "lienParentedata",
    "nationnalitedata",
    "ethniedata",
    "typeCartedata",
    "situationMatrimonialedata",
    "professionsPatientData",
    "actesList",
    "typeOrdonnance",
    "medicament",
    "caisseOuverteData",
    "roledata",
    "servicesdata",
    "specialitedata",
    "identificationdata",
    "userdata",
    "posolgieData",
    "typeExamen",
    "maladiesData",
    "patientsdata",
    "motifsCentreData",
    "sidebardata",
    "centresantedata",
    "assurancesGlobalesData",
    "controlePBFReducer",
  ],

  // whitelist: [
  //             'caisseOuverteData',
  //             'roledata', 'servicesdata', 'specialitedata',
  //             'identificationdata', 'userdata', 'posolgieData', 'typeExamen', 'patientsdata', 'motifsCentreData', 'sidebardata', 'centresantedata', 'assurancesGlobalesData'
  //           ]
};

const persistReducers = persistReducer(persistConfig, rootReducer)

// "build": "cross-env NODE_ENV=production react-scripts build",

//desactiver logger en production
let middlewareTools = []

if(config.virtual.ENV === "prod"){
  middlewareTools = [...middlewareTools, thunk]
}else if(config.virtual.ENV === "dev") {
  middlewareTools = [...middlewareTools, thunk, logger]
}

const middleware = composeWithDevTools(applyMiddleware(...middlewareTools));


//eslint-disable-next-line
export default () => {

  let store = createStore(persistReducers, middleware)

  let persistor = persistStore(store)
  
  return {store, persistor}
}
//--------------------------------------------------------------------------//
//                          Redux persist Import End                        //
//--------------------------------------------------------------------------//